<template>
    <div class="h-full bg-white-pure flex flex-col items-center justify-center p-6">
        <v-alert dense outlined type="error">
            {{alertText}}
        </v-alert>
    </div>
</template>

<script>
export default {
    name: 'InvalidAppKey',
    data() {
        return {
            alertText: 'Uh-oh, you do not have a valid API key, please contact Jeeves to start an account today.'
        }
    }
}
</script>
